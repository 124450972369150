.content .item {
    margin: 10px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.info {
    font-weight: bold;
    margin: 0;
    padding: 5px;
    padding-left: 0;
    color: #FB4923;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

label {
    color: #333;
}



h3 {
    margin: 0;
    font-size: 26px;
}