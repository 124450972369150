body,
html {
    overflow: auto;
    margin: 0;
    padding: 0;
    height: 100%;
}

table {
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #999;
}

td,
th {
    border: 1px dashed #999;
    padding: 5px 10px;
}

tr:nth-child(2n+1) {
    background-color: snow;
}

tr:nth-child(2n) {
    background-color: aliceblue;
}

thead tr {
    background-color: azure !important;

}

tr {
    transition: .1s;
}

tbody tr:hover {
    background-color: #999;
}

.btn {
    margin: 5px;
    border-radius: 8px;
    background-color: #259DD9;
    padding: 8px 14px;
    border: none;
    color: #fff;
    transition: .1s;
}

.btn:active {
    background-color: #2576d9 !important;

}

.btn:hover {
    background-color: #2582d9;
}

.App {
    padding-top: 10px;
}

.App p {
    text-align: center;
    margin: 5px 0;
}

.options {
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
}

.options .btn {
    margin-left: 10px;
}

h2 {
    text-align: center;
    margin: 0;
}

.App {
    margin: 0 auto;
}

.listContent {
    display: grid;
    grid-template-columns: 50% 50%;
}

.input {
    background-color: transparent;
    outline: none;
    border: 2px solid #66666666;
    transition: all .2s;
    padding: 5px;
    margin: 0 5px;
    border-radius: 5px;
    vertical-align: middle;
}

.input:active,
.input:focus,
.input:focus-visible {
    border: 2px solid #259DD9 !important;
}

.contentBox {
    backdrop-filter: blur(30px);
    border-radius: 12px;
}

.loading img {
    width: 100%;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    padding: 10px;
    text-align: center;
}

.footer a {
    text-decoration: none;
    color: rgb(255, 160, 176);
}

.modal {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    max-height: 90%;
    opacity: 0;
    width: 0;
    transition: all .4s;
    background-color: #ffffff95;
    backdrop-filter: blur(11px);
}

.show {
    opacity: 1;
    width: 80%;
}

.datePicker {
    background: transparent;
    border: 2px solid #66666666;
    border-radius: 5px;
    padding: 5px;
    margin: 0 5px;
    outline: none;
    min-width: 100px;
    min-height: 15px;
    vertical-align: middle;
    transition: all .2s;
}

.datePicker:active,
.datePicker:focus,
.datePicker:focus-visible {
    border: 2px solid #259DD9 !important;
}

@media (min-width:1200px) {
    .App {
        width: 1200px !important;
    }
    .listContent {
        grid-template-columns:  33% 33% 33%;

    }
}

@media (min-width:750px) {
    .App {
        width: 750px;
    }
}

@media (max-width:680px) {
    .listContent {
        grid-template-columns: 100%;

    }
}